import React, { useEffect } from "react"

import { Container, Row, Col } from "react-bootstrap"
import PageWrapper from "../components/PageWrapper"
import { Section, Title, Text } from "../components/Core"
import { t, Trans } from "@lingui/macro"
import SEO from "../components/SEO"

const Applications = ({ location }) => {
  useEffect(() => {
    const script = document.createElement("script")
    script.src = "https://i.am.ai/usecases/static/js/main.3c83c331.js"
    script.async = true
    document.body.appendChild(script)
  })
  return (
    <>
      <SEO
        pathname={location.pathname}
        title={t`KI Anwendungen`}
        description={t`Inspirationen für KI-Lösungen die sie in Ihre Prozesse und Produkte integrieren können.`}
      />
      <PageWrapper footerDark>
        <Section className="pb-0">
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center text-center">
              <Col lg="7">
                <Title variant="hero">
                  <Trans>KI-Anwendungen</Trans>
                </Title>
                <Text>
                  <Trans>Inspirationen für Ihr KI-Projekt</Trans>
                </Text>
              </Col>
            </Row>
          </Container>
        </Section>
        <link
          href="https://i.am.ai/usecases/static/css/main.14835b9a.css"
          rel="stylesheet"
        ></link>
        <div
          id="root"
          className="app-container"
          css={{ marginTop: 100, minHeight: 1000 }}
        ></div>
      </PageWrapper>
    </>
  )
}

export default Applications
